// actions/types.js
export const FETCH_DATA_REQUEST = "FETCH_DATA_REQUEST";
export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_DATA_FAILURE = "FETCH_DATA_FAILURE";

export const FETCH_DATA_2_REQUEST = "FETCH_DATA_2_REQUEST";
export const FETCH_DATA_2_SUCCESS = "FETCH_DATA_2_SUCCESS";
export const FETCH_DATA_2_FAILURE = "FETCH_DATA_2_FAILURE";

export const FETCH_DATA_3_REQUEST = "FETCH_DATA_3_REQUEST";
export const FETCH_DATA_3_SUCCESS = "FETCH_DATA_3_SUCCESS";
export const FETCH_DATA_3_FAILURE = "FETCH_DATA_3_FAILURE";

export const FETCH_DATA_4_REQUEST = "FETCH_DATA_4_REQUEST";
export const FETCH_DATA_4_SUCCESS = "FETCH_DATA_4_SUCCESS";
export const FETCH_DATA_4_FAILURE = "FETCH_DATA_4_FAILURE";

export const GAMES_DATA_REQUEST = "GAMES_DATA_REQUEST";
export const GAMES_DATA_SUCCESS = "GAMES_DATA_SUCCESS";
export const GAMES_DATA_FAILURE = "GAMES_DATA_FAILURE";

export const PLACE_ORDER_REQUEST = "PLACE_ORDER_REQUEST";
export const PLACE_ORDER_SUCCESS = "PLACE_ORDER_SUCCESS";
export const PLACE_ORDER_FAILURE = "PLACE_ORDER_FAILURE";

export const GAME_RESULT_REQUEST = "GAME_RESULT_REQUEST";
export const GAME_RESULT_SUCCESS = "GAME_RESULT_SUCCESS";
export const GAME_RESULT_FAILURE = "GAME_RESULT_FAILURE";

export const WALLET_HISTORY_REQUEST = "WALLET_HISTORY_REQUEST";
export const WALLET_HISTORY_SUCCESS = "WALLET_HISTORY_SUCCESS";
export const WALLET_HISTORY_FAILURE = "WALLET_HISTORY_FAILURE";

export const WINNER_LIST_REQUEST = "WINNER_LIST_REQUEST";
export const WINNER_LIST_SUCCESS = "WINNER_LIST_SUCCESS";
export const WINNER_LIST_FAILURE = "WINNER_LIST_FAILURE";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";